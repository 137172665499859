<template>
  <div class="py-4">
    <div id="Hodo_UTD" ref="Hodo_UTD" class="container position-relative cs-container">
      <div class="flex justify-end mb-2 gap-4">
        <el-tooltip class="item" effect="dark" content="In nội dung" placement="top">
          <div @click="handleViewContent(true)" class>
            <svg
              width="28"
              height="28"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.5 2.5H17.5V7.5H21.5V18.5H17.5V21.5H6.5V18.5H2.5V7.5H6.5V2.5ZM6.5 17.5V13.5H17.5V17.5H20.5V8.5H3.5V17.5H6.5ZM16.5 3.5V7.5H7.5V3.5H16.5ZM16.5 20.5H7.5V14.5H16.5V20.5Z"
                fill="#20409B"
              />
            </svg>

            <!-- <svg class="mr-0" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M7 10C6.80222 10 6.60888 10.0586 6.44443 10.1685C6.27998 10.2784 6.15181 10.4346 6.07612 10.6173C6.00043 10.8 5.98063 11.0011 6.01921 11.1951C6.0578 11.3891 6.15304 11.5673 6.29289 11.7071C6.43275 11.847 6.61093 11.9422 6.80491 11.9808C6.99889 12.0194 7.19996 11.9996 7.38268 11.9239C7.56541 11.8482 7.72159 11.72 7.83147 11.5556C7.94135 11.3911 8 11.1978 8 11C8 10.7348 7.89464 10.4804 7.70711 10.2929C7.51957 10.1054 7.26522 10 7 10ZM19 6H18V3C18 2.73478 17.8946 2.48043 17.7071 2.29289C17.5196 2.10536 17.2652 2 17 2H7C6.73478 2 6.48043 2.10536 6.29289 2.29289C6.10536 2.48043 6 2.73478 6 3V6H5C4.20435 6 3.44129 6.31607 2.87868 6.87868C2.31607 7.44129 2 8.20435 2 9V15C2 15.7956 2.31607 16.5587 2.87868 17.1213C3.44129 17.6839 4.20435 18 5 18H6V21C6 21.2652 6.10536 21.5196 6.29289 21.7071C6.48043 21.8946 6.73478 22 7 22H17C17.2652 22 17.5196 21.8946 17.7071 21.7071C17.8946 21.5196 18 21.2652 18 21V18H19C19.7956 18 20.5587 17.6839 21.1213 17.1213C21.6839 16.5587 22 15.7956 22 15V9C22 8.20435 21.6839 7.44129 21.1213 6.87868C20.5587 6.31607 19.7956 6 19 6ZM8 4H16V6H8V4ZM16 20H8V16H16V20ZM20 15C20 15.2652 19.8946 15.5196 19.7071 15.7071C19.5196 15.8946 19.2652 16 19 16H18V15C18 14.7348 17.8946 14.4804 17.7071 14.2929C17.5196 14.1054 17.2652 14 17 14H7C6.73478 14 6.48043 14.1054 6.29289 14.2929C6.10536 14.4804 6 14.7348 6 15V16H5C4.73478 16 4.48043 15.8946 4.29289 15.7071C4.10536 15.5196 4 15.2652 4 15V9C4 8.73478 4.10536 8.48043 4.29289 8.29289C4.48043 8.10536 4.73478 8 5 8H19C19.2652 8 19.5196 8.10536 19.7071 8.29289C19.8946 8.48043 20 8.73478 20 9V15Z"
                fill="#20409B"
              />
            </svg>-->
          </div>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="Xem nội dung" placement="top">
          <div
            @click="handleViewContent()"
            class
            data-toggle="tooltip"
            data-placement="top"
            title="Xem nội dung"
          >
            <svg
              width="28"
              height="28"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8.5 6.5H15.5V7.5H8.5V6.5Z" fill="black" />
              <path d="M15.5 10.5H8.5V11.5H15.5V10.5Z" fill="black" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.5 2.5H4.5V21.5H19.5V2.5ZM18.5 16.5V3.5H5.5V16.5H18.5ZM5.5 17.5V20.5H18.5V17.5H5.5Z"
                fill="#20409B"
              />
            </svg>

            <!-- <svg
              class="mr-0"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.9201 11.6C19.9001 6.91 16.1001 4 12.0001 4C7.90007 4 4.10007 6.91 2.08007 11.6C2.025 11.7262 1.99658 11.8623 1.99658 12C1.99658 12.1377 2.025 12.2738 2.08007 12.4C4.10007 17.09 7.90007 20 12.0001 20C16.1001 20 19.9001 17.09 21.9201 12.4C21.9751 12.2738 22.0036 12.1377 22.0036 12C22.0036 11.8623 21.9751 11.7262 21.9201 11.6ZM12.0001 18C8.83007 18 5.83007 15.71 4.10007 12C5.83007 8.29 8.83007 6 12.0001 6C15.1701 6 18.1701 8.29 19.9001 12C18.1701 15.71 15.1701 18 12.0001 18ZM12.0001 8C11.2089 8 10.4356 8.2346 9.77779 8.67412C9.11999 9.11365 8.6073 9.73836 8.30455 10.4693C8.0018 11.2002 7.92258 12.0044 8.07693 12.7804C8.23127 13.5563 8.61223 14.269 9.17164 14.8284C9.73105 15.3878 10.4438 15.7688 11.2197 15.9231C11.9956 16.0775 12.7999 15.9983 13.5308 15.6955C14.2617 15.3928 14.8864 14.8801 15.3259 14.2223C15.7655 13.5645 16.0001 12.7911 16.0001 12C16.0001 10.9391 15.5786 9.92172 14.8285 9.17157C14.0783 8.42143 13.0609 8 12.0001 8ZM12.0001 14C11.6045 14 11.2178 13.8827 10.8889 13.6629C10.56 13.4432 10.3037 13.1308 10.1523 12.7654C10.0009 12.3999 9.96133 11.9978 10.0385 11.6098C10.1157 11.2219 10.3061 10.8655 10.5859 10.5858C10.8656 10.3061 11.2219 10.1156 11.6099 10.0384C11.9978 9.96126 12.4 10.0009 12.7654 10.1522C13.1309 10.3036 13.4432 10.56 13.663 10.8889C13.8828 11.2178 14.0001 11.6044 14.0001 12C14.0001 12.5304 13.7894 13.0391 13.4143 13.4142C13.0392 13.7893 12.5305 14 12.0001 14Z"
                fill="#20409B"
              />
            </svg>-->
          </div>
        </el-tooltip>

        <div @click="closeSearchResult" class="cursor-pointer">
          <svg width="30" height="30" viewBox="0 0 33 33" fill="none">
            <rect
              x="23.2937"
              y="8.11432"
              width="2"
              height="22"
              rx="1"
              transform="rotate(45 23.2937 8.11432)"
              fill="#4F4F4F"
            />
            <rect
              x="24.7079"
              y="23.6707"
              width="2"
              height="22"
              rx="1"
              transform="rotate(135 24.7079 23.6707)"
              fill="#4F4F4F"
            />
          </svg>
        </div>
      </div>
      <div class="cs-collapse" :class="isCollapse && 'is-collapse'">
        <svg
          @click="handleChangeCollapse"
          :class="isCollapse ? '' : 'fliped'"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M20.9985 6.00001L7.99959 6.16709L8.02529 8.16693L21.0242 7.99985L20.9985 6.00001ZM21.0628 10.9996L11.0636 11.1281L11.0893 13.128L21.0885 12.9994L21.0628 10.9996ZM21.1527 17.999L21.127 15.9992L8.12811 16.1663L8.15381 18.1661L21.1527 17.999ZM3.03098 8.64115L6.65682 12.1848L3.12325 15.8206L4.55126 17.2123L9.48659 12.1485L4.42274 7.21315L3.03098 8.64115Z"
            fill="#4F4F4F"
          />
        </svg>
      </div>
      <div class="flex flex-row h-full position-relative" v-loading="isLoading">
        <div
          id="upToDateSidebar"
          class="update-page p-3 bg-white"
          :class="isCollapse?'d-none':'update-page'"
        >
          <div ref="upToDateSidebar" v-html="content"></div>
        </div>
        <div
          v-html="post"
          ref="upToDateMain"
          id="upToDateMain"
          :class="isCollapse?'body-full':'body-page'"
          class="bg-white py-3 px-4 pr-4 ml-6 body-content"
        ></div>
      </div>

      <div v-if="isShowBackTop" @click="handleScrollToTop" class="button-backTop">
        <svg
          width="40"
          height="40"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
            stroke="#20409B"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11.7625 15.2375L16 11L20.2375 15.2375"
            stroke="#20409B"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16 21V11"
            stroke="#20409B"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
    <!-- <div v-html="content" class="update-page p-3 bg-white col-sm"></div>
    <div v-html="post" class="body-page bg-white py-3 px-4 pr-4 ml-6 col-sm"></div>-->
  </div>
</template>
<script>
import { debounce } from 'lodash'

export default {
  name: 'UptoDatePage',
  data () {
    return {
      isLoading: false,
      slug: '',
      dialogVisible: false,
      detailArticle: [],
      contentRaw: '',
      content: '',
      postRaw: '',
      post: '',
      isClickHandlerAttached: false,
      isCollapse: false,
      isShowBackTop: false
    }
  },
  async mounted () {
    // window.addEventListener('scroll', this.handleShowScroll)
    const self = this

    self.slug = self.$route.query.slug
    await self.handleDetailChange(self.slug).then(async res => {
      await self.parseAndBindClickEvents({
        html: res?.outlineHtml,
        refsData: this.$refs.upToDateSidebar,
        isSidebar: true
      })
      await self.parseAndBindClickEvents({
        html: res.bodyHtml,
        refsData: this.$refs.upToDateMain,
        isSidebar: false
      })
    })

    window.$(document).ready(function () {
      const postEL = document.querySelector('#upToDateMain')
      if (postEL) {
        postEL.addEventListener('scroll', e => {
          if (postEL.scrollTop > 1000) {
            self.isShowBackTop = true
          } else {
            self.isShowBackTop = false
          }
        })
      }
    })
  },
  watch: {
    contentRaw: {
      async handler (data) {
        if (data === '') return
        await this.parseAndBindClickEvents({
          html: data,
          refsData: this.$refs.upToDateSidebar,
          isSidebar: true
        })
      }
    },
    postRaw: {
      async handler (data) {
        if (data === '') return
        await this.parseAndBindClickEvents({
          html: data,
          refsData: this.$refs.upToDateMain,
          isSidebar: false
        })
      }
    }
  },
  methods: {
    async parseAndBindClickEvents ({ html, refsData, isSidebar }) {
      const self = this
      try {
        const parser = new DOMParser()
        const doc = parser.parseFromString(html, 'text/html')
        const anchorLinks = doc.querySelectorAll('a')

        anchorLinks.forEach(link => {
          const href = link.getAttribute('href')

          let newHref = href

          if (href) {
            if (href.startsWith('#')) {
              newHref = href
            } else if (href.includes('/grade')) {
              newHref = href.replace('/contents/', '')
            } else if (href.includes('/abstract')) {
              const abstractIndex = href.indexOf('/abstract/')
              if (abstractIndex !== -1) {
                newHref = href.slice(abstractIndex)
              } else {
                newHref = href
              }
            } else if (href.includes('topicKey') || href.includes('/image')) {
              newHref = href.split('/')[2]
            } else if (href.startsWith('/contents')) {
              newHref = href.split('/')[2].split('?')[0]
            }
            link.setAttribute('href', 'javascript:;')
            link.setAttribute('data-href', newHref)
          }
        })

        if (isSidebar) {
          self.content = doc.documentElement.outerHTML
        } else {
          self.post = doc.documentElement.outerHTML
        }

        self.$nextTick(() => {
          self.attachClickHandler(refsData)
        })
      } catch (error) {
        console.log(error)
      }
    },
    attachClickHandler (refsData) {
      const self = this
      const anchorLinksInRenderedHTML = refsData.querySelectorAll('a')
      anchorLinksInRenderedHTML.forEach(link => {
        link.addEventListener('click', event => {
          const href = event.target.getAttribute('data-href')

          if (href) {
            if (href.startsWith('#')) {
              const targetElement = document.querySelector(href)
              if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' })
                // const offset = 80
                // const rect = targetElement.getBoundingClientRect()
                // const scrollTop =
                //   window.scrollY || document.documentElement.scrollTop
                // window.scrollTo({
                //   top: scrollTop + rect.top - offset,
                //   behavior: 'smooth'
                // })
                // event.preventDefault()
              }
            } else if (href.includes('grade/')) {
              const location = `${
                window.location.origin
              }/#/doctor/uptodate-grade/${href.replace('grade/', '')}`
              const absoluteURL = new URL(location).href
              self.handleDebounce(absoluteURL)
            } else if (href.includes('topicKey') || href.includes('/image')) {
              const location = `${window.location.origin}/#/doctor/uptodate-graphic/${href}`
              const absoluteURL = new URL(location).href
              self.handleDebounce(absoluteURL)
            } else if (href.includes('/abstract')) {
              const location = `${
                window.location.origin
              }/#/doctor/uptodate-abstract?citation_range=${
                href.split('/')[2]
              }&slug=${this.$route.query.slug}`
              const absoluteURL = new URL(location).href
              self.handleDebounce(absoluteURL)
            } else {
              const location = `${window.location.origin}/#/doctor/uptodate?slug=${href}`
              const absoluteURL = new URL(location).href
              self.handleDebounce(absoluteURL)
              // self.handleDetailChange(href).then(() => {

              //   self.$router
              //     .push({ path: '/doctor/uptodate', query: { slug: href } })
              //     .catch(() => {})
              // })
            }
          }
        })
      })
    },
    handleDebounce: debounce(function (absoluteURL) {
      window.open(absoluteURL, '_blank')
    }, 500),
    async handleDetailChange (value) {
      try {
        this.isLoading = true
        const params = {
          slug: value
        }
        const result = await this.$rf
          .getRequest('DoctorRequest')
          .getDetailArticle(params)

        this.contentRaw = result.data.data?.outlineHtml
        this.postRaw = result.data.data.bodyHtml
        this.isLoading = false
        return result.data?.data
      } catch (error) {
        console.log('', error)
      } finally {
        this.isLoading = false
      }
    },
    // async openArticles (item) {
    //   const slug = item.url.split('/')[2].split('?')[0]
    //   console.log(slug)
    //   this.$router.push({ path: '/doctor/uptodate', query: { slug: slug } })
    // },
    async closeSearchResult () {
      this.$store.commit('upToDate/setSearchValue', '')
      this.$router
        .push({
          path: '/'
        })
        .catch(() => {})
    },
    handleChangeCollapse () {
      this.isCollapse = !this.isCollapse
    },
    handleScrollToTop () {
      this.$refs.upToDateMain.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    handleViewContent (isPrint) {
      this.$store.commit('upToDate/setContent', this.post)

      if (isPrint) {
        this.$router.push({
          name: 'PrintOnyContent',
          query: {
            type: 'print'
          }
        })
      } else {
        this.$router.push({
          name: 'PrintOnyContent'
        })
      }
      // const w = window.open()
      // w.document.write(this.post)
      // w.document.write(
      //   `
      //   <link rel="stylesheet" type="text/css" href="https://www.uptodate.com/app/utd-all-eea42eeac4c944e52b22-2.css" />
      //   <link rel="stylesheet" type="text/css" href="https://www.uptodate.com/app/utd-all-eea42eeac4c944e52b22-3.css" />
      //   <link rel="stylesheet" type="text/css" href="https://www.uptodate.com/app/utd-all-eea42eeac4c944e52b22-4.css" />
      //    `
      // )
      // w.document.close()
      // w.print()
      // w.close()
    }
  },
  destroyed () {
    window.removeEventListener('scroll', () => {})
  }
}
//
</script>
<style scoped lang="scss">
// @import url("https://www.uptodate.com/app/utd-all-eea42eeac4c944e52b22-1.css");
@import url("https://www.uptodate.com/app/utd-all-eea42eeac4c944e52b22-2.css");
@import url("https://www.uptodate.com/app/utd-all-eea42eeac4c944e52b22-3.css");
@import url("https://www.uptodate.com/app/utd-all-eea42eeac4c944e52b22-4.css");

.result {
  width: 100%;
}

.top-bar {
  height: 20vh;
  width: 100%;
  border-bottom: solid 2px;
  background-color: #fff;

  .go-back {
    cursor: pointer;
  }
}

.back {
  cursor: pointer;
}

.close-button {
  position: absolute;
  right: 10px;
  top: 10px;
}

.update-page {
  width: 30%;
  height: 100%;
  overflow-y: scroll;
}

.body-page {
  width: 70%;
}

#outlineCalculators a,
#outlineGraphics a,
#outlineTopics a,
.utdOutlineCalculators a,
.utdOutlineGraphics a,
.utdOutlineTopics a {
  padding: 6px 0;
  margin: 2px 0;
}

::v-deep {
  .update-page {
    #innerOutline {
      padding: 0;
      margin: 0;
    }
    #outlineRelatedGraphicsLinks {
      display: none !important;
    }
    #outlineTopics ul {
      padding: 0;
      margin: 0;
    }
  }
  .body-page {
    font-size: 16px !important;
    .headingAnchor {
      .h1 {
        display: block;
      }
    }

    #topicTitle {
      font-size: 32px !important;
      font-weight: 700;
      margin-bottom: 16px;
      display: block !important;
    }
    #literatureReviewDate,
    #topicAgreement {
      margin-bottom: 24px;
    }

    #topicText {
      display: inline-block;
    }

    .authorSectionElem {
      display: inline-flex;
      background-color: rgb(0 91 146 / 10%);
      padding: 8px;
      font-size: 13px;
      width: 100%;

      .authorsElementsLeft {
        width: 30%;
        padding-right: 4px;
      }

      .authorsElementsRight {
        flex: 1 1 0%;
        padding-left: 4px;
        font-size: 12px;
      }

      #topicContributors {
        dt {
          text-transform: uppercase;
        }
      }

      .disclosureLink {
        margin-bottom: 0;
      }
    }

    #references h1 {
      font-size: 1.5rem !important;
    }
  }

  .cs-collapse {
    position: absolute;
    cursor: pointer;
    left: 27%;
    top: 45px;
    z-index: 100;
  }

  .fliped {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .is-collapse {
    left: 3% !important;
  }

  .body-full {
    width: 100% !important;
  }

  .button-backTop {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    cursor: pointer;
  }

  .body-content {
    height: 100%;
    overflow-y: scroll;
  }

  .cs-button-icon {
    padding: 2px;
    border: 1px solid #9e9d9d;
    border-radius: 4px;
    cursor: pointer;
  }

  .cs-container {
    height: calc(100vh - 120px);
    overflow: hidden;
    // overflow-y: scroll;
    position: relative;
  }
}
</style>
